export const TYPE = [
'Close',
'Place',
'Street',
'Avenue',
'Lane',
'Crescent',
'Way',
'Parade',
'Avenue',
'Place',
'Avenue',
'Grove',
'Place',
'Place',
'Close',
'Way',
'Place',
'Place',
'Street',
'Street',
'Place',
'Parade',
'Circuit',
'Street',
'Place',
'Avenue',
'Place',
'Street',
'Street',
'Glen',
'Street',
'Circuit',
'Place',
'Lane',
'Road',
'Mart',
'Street',
'Court',
'Street',
'Avenue',
'Street',
'Street',
'Road',
'Road',
'Street',
'Place',
'Place',
'Place',
'Street',
'Place',
'Lane',
'Place',
'Street',
'Lane',
'Place',
'Court',
'Place',
'Circuit',
'Place',
'Place',
'Street',
'Close',
'Street',
'Place',
'Avenue',
'Road',
'Street',
'Street',
'Avenue',
'Close',
'Drive',
'Road',
'Crescent',
'Street',
'Road',
'Road',
'Parade',
'Avenue',
'Grove',
'Drive',
'Street',
'Place',
'Street',
'Place',
'Street',
'Street',
'Street',
'Street',
'Street',
'Street',
'Street',
'Road',
'Court',
'Street',
'Road',
'Road',
'Street',
'Street',
'Street',
'Place',
'Place',
'Street',
'Road',
'Place',
'Street',
'Street',
'Street',
'Street',
'Avenue',
'Drive',
'Street',
'Street',
'Road',
'Street',
'Street',
'Street',
'Avenue',
'Avenue',
'Place',
'Place',
'Street',
'Place',
'Street',
'Crescent',
'Street',
'Way',
'Close',
'Place',
'Way',
'Court',
'Road',
'Place',
'Place',
'Place',
'Road',
'Street',
'Street',
'Place',
'Street',
'Close',
'Circuit',
'Street',
'Place',
'Lane',
'Street',
'Place',
'Place',
'Close',
'Street',
'Place',
'Place',
'Avenue',
'Place',
'Road',
'Place',
'Street',
'Place',
'Lane',
'Avenue',
'Way',
'Street',
'Place',
'Road',
'Crescent',
'Street',
'Close',
'Street',
'Place',
'Street',
'Place',
'Lane',
'Place',
'Street',
'Street',
'Street',
'Crescent',
'Avenue',
'Street',
'Street',
'Place',
'Street',
'Crescent',
'Place',
'Street',
'Street',
'Street',
'Avenue',
'Avenue',
'Street',
'Avenue',
'Street',
'Lane',
'Street',
'Place',
'Place',
'Place',
'Avenue',
'Street',
'Place',
'Street',
'Street',
'Street',
'Place',
'Place',
'Avenue',
'Crescent',
'Lane',
'Street',
'Glen',
'Street',
'Place',
'Street',
'Street',
'Place',
'Road',
'Street',
'Road',
'Street',
'Road',
'Place',
'Avenue',
'Place',
'Place',
'Avenue',
'Street',
'Street',
'Street',
'Road',
'Road',
'Street',
'Place',
'Street',
'Street',
'Street',
'Street',
'Place',
'Street',
'Street',
'Street',
'Glen',
'Road',
'Crescent',
'Circle',
'Street',
'Place',
'Close',
'Street',
'Road',
'Road',
'Court',
'Street',
'Crescent',
'Close',
'Place',
'Place',
'Crescent',
'Street',
'Close',
'Drive',
'Court',
'Avenue',
'Street',
'Place',
'Crescent',
'Street',
'Circuit',
'Street',
'Court',
'Place',
'Close',
'Place',
'Street',
'Place',
'Street',
'Avenue',
'Place',
'Street',
'Avenue',
'Way',
'Street',
'Street',
'Street',
'Street',
'Place',
'Street',
'Lane',
'Place',
'Street',
'Crescent',
'Way',
'Road',
'Street',
'Place',
'Road',
'Crescent',
'Road',
'Street',
'Circuit',
'Street',
'Close',
'Place',
'Crescent',
'Street',
'Crescent',
'Place',
'Circuit',
'Street',
'Street',
'Street',
'Place',
'Place',
'Street',
'Street',
'Place',
'Place',
'Street',
'Street',
'Avenue',
'Avenue',
'Parade',
'Road',
'Street',
'Street',
'Close',
'Crescent',
'Street',
'Place',
'Place',
'Avenue',
'Crescent',
'Street',
'Street',
'Place',
'Place',
'Street',
'Crescent',
'Street',
'Avenue',
'Place',
'Street',
'Place',
'Place',
'Road',
'Place',
'Lane',
'Street',
'Avenue',
'Avenue',
'Street',
'Place',
'Road',
'Close',
'Place',
'Street',
'Place',
'Place',
'Street',
'Place',
'Avenue',
'Street',
'Street',
'Street',
'Close',
'Street',
'Close',
'Street',
'Place',
'Circuit',
'Crescent',
'Crescent',
'Avenue',
'Crescent',
'Street',
'Place',
'Crescent',
'Road',
'Road',
'Street',
'Glen',
'Drive',
'Place',
'Street',
'Street',
'Lane',
'Place',
'Place',
'Street',
'Street',
'Place',
'Street',
'Place',
'Walk',
'Lane',
'Place',
'Place',
'Lane',
'Place',
'Place',
'Street',
'Place',
'Place',
'Street',
'Court',
'Avenue',
'Place',
'Avenue',
'Place',
'Avenue',
'Place',
'Place',
'Street',
'Crescent',
'Street',
'Street',
'Street',
'Crescent',
'Way',
'Street',
'Street',
'Street',
'Place',
'Boulevard',
'Walk',
'Street',
'Crescent',
'Parade',
'Avenue',
'Lane',
'Street',
'Close',
'Street',
'Way',
'Grove',
'Street',
'Street',
'Place',
'Street',
'Place',
'Place',
'Place',
'Road',
'Place',
'Street',
'Crescent',
'Court',
'Place',
'Close',
'Street',
'Road',
'Street',
'Court',
'Avenue',
'Place',
'Place',
'Court',
'Road',
'Parade',
'Place',
'Crescent',
'Road',
'Street',
'Place',
'Place',
'Street',
'Place',
'Close',
'Close',
'Street',
'Street',
'Place',
'Place',
'Place',
'Street',
'Way',
'Street',
'Place',
'Avenue',
'Way',
'Street',
'Street',
'Place',
'Street',
'Lane',
'Place',
'Street',
'Street',
'Street',
'Boulevard',
'Street',
'Place',
'Place',
'Street',
'Road',
'Place',
'Place',
'Street',
'Avenue',
'Street',
'Avenue',
'Place',
'Road',
'Street',
'Crescent',
'Street',
'Place',
'Crescent',
'Place',
'Avenue',
'Road',
'Place',
'Place',
'Street',
'Way',
'Street',
'Place',
'Street',
'Road',
'Street',
'Place',
'Place',
'Street',
'Circuit',
'Street',
'Street',
'Street',
'Street',
'Court',
'Street',
'Place',
'Place',
'Place',
'Street',
'Crescent',
'Street',
'Place',
'Street',
'Avenue',
'Street',
'Place',
'Street',
'Street',
'Place',
'Street',
'Place',
'Crescent',
'Drive',
'Place',
'Street',
'Place',
'Avenue',
'Street',
'Avenue',
'Place',
'Street',
'Close',
'Place',
'Street',
'Grove',
'Close',
'Avenue',
'Place',
'Grove',
'Place',
'Street',
'Place',
'Street',
'Place',
'Street',
'Place',
'Place',
'Place',
'Street',
'Place',
'Lane',
'Street',
'Avenue',
'Road',
'Street',
'Crescent',
'Street',
'Close',
'Court',
'Street',
'Avenue',
'Street',
'Street',
'Place',
'Crescent',
'Place',
'Way',
'Circle',
'Street',
'Street',
'Court',
'Place',
'Place',
'Street',
'Street',
'Place',
'Street',
'Place',
'Crescent',
'Street',
'Place',
'Street',
'Lane',
'Place',
'Lane',
'Place',
'Avenue',
'Way',
'Crescent',
'Street',
'Way',
'Close',
'Place',
'Street',
'Street',
'Street',
'Place',
'Grove',
'Way',
'Circuit',
'Place',
'Close',
'Road',
'Road',
'Street',
'Crescent',
'Circuit',
'Street',
'Street',
'Place',
'Street',
'Street',
'Street',
'Place',
'Place',
'Street',
'Place',
'Avenue',
'Street',
'Close',
'Place',
'Street',
'Place',
'Street',
'Place',
'Place',
'Grove',
'Street',
'Place',
'Grove',
'Place',
'Street',
'Street',
'Place',
'Street',
'Grove',
'Street',
'Street',
'Road',
'Street',
'Place',
'Grove',
'Place',
'Street',
'Lane',
'Place',
'Way',
'Crescent',
'Street',
'Street',
'Street',
'Road',
'Street',
'Glen',
'Close',
'Avenue',
'Way',
'Drive',
'Lane',
'Avenue',
'Avenue',
'Street',
'Avenue',
'Avenue',
'Street',
'Place',
'Place',
'Place',
'Place',
'Crescent',
'Road',
'Grove',
'Street',
'Street',
'Place',
'Street',
'Road',
'Crescent',
'Road',
'Street',
'Street',
'Street',
'Place',
'Street',
'Place',
'Fairway',
'Place',
'Street',
'Crescent',
'Place',
'Road',
'Street',
'Place',
'Place',
'Place',
'Street',
'Street',
'Close',
'Avenue',
'Place',
'Street',
'Street',
'Way',
]
