export const POSTCODE = [
'2767',
'2761',
'2763',
'2769',
'2770',
'2770',
'2761',
'2148',
'2088',
'2761',
'2148',
'2763',
'2166',
'2767',
'2767',
'2767',
'2767',
'2759',
'2148',
'2088',
'2148',
'2767',
'2759',
'2148',
'2759',
'2761',
'2759',
'7000',
'2148',
'2759',
'2766',
'2155',
'2761',
'2088',
'2767',
'7000',
'2148',
'2767',
'2760',
'2760',
'2760',
'2761',
'2148',
'2148',
'2769',
'2767',
'2148',
'2761',
'7000',
'2766',
'2088',
'2761',
'2766',
'7000',
'2148',
'2155',
'2763',
'2759',
'2763',
'7000',
'2760',
'2759',
'2769',
'2763',
'2767',
'2088',
'2148',
'2767',
'2148',
'2763',
'2148',
'2148',
'2761',
'2148',
'2760',
'2166',
'2769',
'2761',
'2763',
'2767',
'2770',
'2763',
'2767',
'2761',
'2088',
'2148',
'2769',
'2148',
'2761',
'2148',
'2155',
'2767',
'2759',
'2759',
'2761',
'2148',
'2760',
'2760',
'2148',
'2767',
'2763',
'2088',
'2088',
'2770',
'2759',
'2770',
'2088',
'2166',
'2088',
'2767',
'2769',
'2767',
'2763',
'2088',
'2760',
'2769',
'2763',
'2148',
'2767',
'2759',
'2088',
'2759',
'2166',
'2769',
'2761',
'2761',
'2766',
'2761',
'2767',
'2759',
'2761',
'2760',
'2770',
'2759',
'2148',
'2760',
'2767',
'2767',
'2763',
'2759',
'2766',
'7000',
'2759',
'2088',
'2760',
'2759',
'2759',
'2759',
'2148',
'2766',
'2767',
'2148',
'2761',
'2759',
'2770',
'2761',
'2769',
'2148',
'2767',
'2148',
'2760',
'2763',
'2148',
'2761',
'2166',
'2759',
'2148',
'2767',
'2759',
'2761',
'2148',
'2767',
'2763',
'2761',
'2148',
'2763',
'2148',
'2763',
'2088',
'2759',
'2770',
'2770',
'2770',
'2766',
'2148',
'2088',
'2760',
'2769',
'2763',
'2088',
'2148',
'2088',
'2759',
'2761',
'2759',
'2767',
'2761',
'2769',
'2155',
'2088',
'2088',
'2148',
'2761',
'2763',
'2148',
'2761',
'2088',
'2770',
'2759',
'2148',
'2155',
'2770',
'2155',
'2148',
'2148',
'2759',
'2148',
'2769',
'2759',
'2761',
'2760',
'2761',
'2148',
'2155',
'2760',
'2769',
'2770',
'2148',
'2763',
'2769',
'2766',
'2767',
'2088',
'2761',
'2148',
'2763',
'2759',
'2767',
'2767',
'2759',
'2769',
'2759',
'2761',
'2761',
'2148',
'2759',
'2763',
'2148',
'2770',
'2763',
'2088',
'2769',
'2767',
'2761',
'2761',
'2767',
'2088',
'2759',
'2763',
'2761',
'2761',
'2761',
'2759',
'2767',
'2770',
'2761',
'2767',
'2759',
'2763',
'2759',
'2761',
'2166',
'2761',
'2148',
'2769',
'2761',
'2148',
'7000',
'2763',
'2770',
'2148',
'2770',
'2769',
'2155',
'2148',
'2760',
'2759',
'2760',
'2761',
'2761',
'2148',
'2148',
'2767',
'2763',
'2761',
'2088',
'2763',
'2769',
'2767',
'2148',
'2761',
'2761',
'2769',
'2763',
'2761',
'2155',
'2166',
'2763',
'2148',
'2763',
'2767',
'2763',
'2770',
'2148',
'2763',
'2770',
'2767',
'2088',
'2148',
'2766',
'2767',
'2088',
'7000',
'2763',
'2761',
'2148',
'2767',
'2766',
'2166',
'2767',
'2760',
'2763',
'2763',
'2767',
'2155',
'2760',
'2770',
'2760',
'2148',
'2760',
'2767',
'2759',
'2148',
'2763',
'2767',
'2148',
'2769',
'2759',
'2770',
'2766',
'2088',
'2759',
'2763',
'2760',
'2770',
'2759',
'2769',
'2759',
'2155',
'2148',
'2760',
'2148',
'2759',
'2760',
'2767',
'2148',
'2759',
'2759',
'2759',
'2763',
'2148',
'2761',
'2769',
'2155',
'2148',
'2767',
'2761',
'2148',
'2759',
'2761',
'2761',
'2166',
'2770',
'2088',
'2761',
'2155',
'2761',
'2761',
'2166',
'2763',
'2761',
'2088',
'2759',
'2767',
'2759',
'2148',
'2761',
'2763',
'2767',
'2763',
'2766',
'2763',
'2759',
'2767',
'2148',
'2166',
'2155',
'2760',
'2763',
'2766',
'2770',
'2763',
'2770',
'2763',
'2148',
'2088',
'2148',
'2767',
'2148',
'2766',
'2760',
'2766',
'2088',
'2148',
'2767',
'2088',
'2166',
'2148',
'2760',
'2759',
'2148',
'2148',
'2767',
'2770',
'2763',
'2759',
'2767',
'2155',
'2770',
'2766',
'2088',
'2148',
'2760',
'2761',
'2155',
'2148',
'2763',
'2760',
'2761',
'2148',
'2761',
'2767',
'2767',
'2148',
'2155',
'2148',
'2759',
'2767',
'2767',
'2761',
'2770',
'2759',
'2763',
'2763',
'2763',
'2770',
'2148',
'2760',
'2166',
'2770',
'2761',
'2767',
'2088',
'2166',
'2766',
'2767',
'2088',
'2759',
'2148',
'2770',
'2759',
'2148',
'2088',
'2148',
'2148',
'2769',
'2148',
'2761',
'2763',
'2763',
'2763',
'2763',
'2761',
'2759',
'2767',
'2148',
'2770',
'2760',
'2761',
'2770',
'2148',
'2088',
'2088',
'2770',
'2759',
'2767',
'2148',
'2763',
'2763',
'2761',
'2761',
'2148',
'2770',
'2760',
'2148',
'2088',
'2088',
'2155',
'2759',
'2767',
'2148',
'2766',
'2769',
'2769',
'2760',
'2759',
'2761',
'2767',
'2148',
'2148',
'2759',
'2769',
'2761',
'2770',
'2763',
'2763',
'2761',
'2148',
'2770',
'2760',
'2148',
'2761',
'2088',
'2769',
'2148',
'2759',
'2763',
'2767',
'2761',
'2766',
'2763',
'2760',
'2759',
'2148',
'2760',
'2761',
'2166',
'2759',
'2763',
'2763',
'2763',
'2763',
'2766',
'2148',
'2148',
'2770',
'2763',
'2763',
'2770',
'2766',
'2767',
'2763',
'2769',
'2088',
'2148',
'2763',
'2770',
'2088',
'2148',
'2148',
'2155',
'2759',
'2769',
'2148',
'2769',
'2767',
'2770',
'2763',
'2761',
'2148',
'2763',
'2166',
'2148',
'2763',
'2761',
'2759',
'2763',
'2166',
'2761',
'2767',
'2766',
'2761',
'2760',
'2759',
'2767',
'2763',
'2763',
'2759',
'2763',
'2763',
'2148',
'2761',
'2770',
'2148',
'2766',
'2148',
'2769',
'2770',
'2155',
'2767',
'2767',
'2767',
'2770',
'2761',
'2767',
'2759',
'2148',
'2148',
'2763',
'2759',
'2148',
'2088',
'2770',
'2763',
'2148',
'2088',
'2770',
'2761',
'2761',
'2148',
'2763',
'2759',
'2759',
'2155',
'2148',
'2759',
'2166',
'2767',
'2770',
'2759',
'2759',
'2767',
'2759',
'2761',
'2763',
'2767',
'2770',
'2769',
'2761',
'2148',
'2759',
'2088',
'2761',
'2767',
'2148',
'2148',
'2148',
'2766',
'2759',
'2767',
'2763',
'2769',
'2155',
'2088',
'2148',
'2166',
'2759',
'2759',
'2088',
'2148',
'2770',
'2759',
'2769',
'2769',
'2760',
'2769',
'2088',
'2769',
'2760',
'2759',
'2761',
'2767',
'2759',
'2766',
'2761',
'2148',
'2769',
'2763',
'2148',
'2088',
'2761',
'2761',
'2769',
'2767',
'2148',
'2767',
'2148',
'2767',
'2769',
'2770',
'2766',
'2166',
'2761',
'2770',
'2763',
'2763',
'2770',
'2763',
'2088',
'2763',
'2759',
'2761',
'2760',
'2088',
'2767',
'2155',
]
